<template>
  <div class="container">
    <div class="section-header">
      <h1
        class="section-title titleLegal fadeInRight"
        style="text-transform: none"
        data-wow-duration="1000ms"
        data-wow-delay="100ms"
      >
        Mentions légales
      </h1>
    </div>
    <div class="rowlegal">
      <h1 class="titleLegal">Identification</h1>
      <p>
        portail web créé et administré par :
        <br />LINAWARE <br />SARL au capital de 109.737 € <br />siège social :
        4, rue Louis Pasteur - 28630 Le Coudray <br />RCS 382 698 793 CHARTRES
        <br />N° TVA FR26382698793 – code APE 6202A
      </p>
      <p>Courriel : info@linaware.eu</p>
      <p>Téléphone : 02 37 35 11 36</p>
      <p>Directeur de la publication : Pierre-André Liné</p>
      <p>
        Site web commercial : <a href="https://www.linaware.eu" target="_blank">linaware.eu</a>.
      </p>

      <h1 class="titleLegal">Hébergement</h1>
      <p>
        Scaleway SAS, BP 438, 75366 Paris CEDEX 08 - SAS au capital de 214 410,50 Euros<br/>
        RCS Paris B 433 115 904 - TVA intra communautaire : FR 35 433 115 90
      </p>
      <h1 class="titleLegal">Propriétés intellectuelles</h1>
      <p>
        L’accès à ce site vous confère un droit d’usage privé et non exclusif de
        ce site.
      </p>
      <p>
        L’ensemble des éléments édités sur ce site, incluant notamment les
        textes, photographies, logos, marques, ... constituent des œuvres de
        l’esprit au sens du Code de la Propriété Intellectuelle. Par conséquent,
        toute utilisation sous forme de représentation, modification,
        reproduction, ... intégrale ou partielle, qui pourrait être faite sans
        le consentement de leurs auteurs ou de leurs ayants-droit, est illicite.
      </p>
      <p>
        Ce site respecte les droits d’auteur. Tous les droits des auteurs des
        œuvres protégées reproduites et communiquées sur ce site, sont réservés.
      </p>

      <h1 class="titleLegal">Cookies</h1>
      <p>Ce site utilise un seul cookie destiné à mémoriser temporairement la connexion de l'utilisateur.</p>

      <h1 class="titleLegal">Données personnelles</h1>
      <p>
        Ce site ne recueille aucune donnée personnelle.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "mentions",
  components: {},

  methods: {},
};
</script>

<style lang="scss">
@media only screen and (max-width: 1200px) {
  .section-header {
    max-width: 800px;
  }
}

.rowlegal {
  margin-right: -15px;
  margin-left: -15px;
}

.section-header {
  text-align: center;
  position: relative;
  margin-bottom: 3.6rem;
  max-width: 960px;
  margin: auto;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.titleLegal {
  font-family: "montserrat-medium", sans-serif;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  margin-top: 6rem;
  margin-bottom: 1.8rem;
  font-size: 3.6rem;
  line-height: 1.25;
  letter-spacing: -0.1rem;
}

p {
  margin-bottom: 3rem;
}

p {
  margin: 0 0 10px;
}

.subtitleLegal {
  font-size: 2.4rem;
  line-height: 1.25;
}
</style>
